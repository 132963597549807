<template>
  <div>
    <iframe :src=grf_url  style="width: 100%; height: 100vh"></iframe>
		<!-- {{ grf_url }} -->
  </div>
</template>

<script>
// import { component } from 'vue-fullscreen'
export default {
  name: "Index",
  // components: {
  //   Fullscreen: component,
  // },
  data () {
    return {
			grf_url : "https://grafana.painet.work"
    }
  },
  methods: {
    toggle () {
      this.fullscreen = !this.fullscreen
    }
  },
	created() {
		if (this.$route.query.url != null) {
			this.grf_url = this.grf_url + this.$route.query.url
			console.log(this.grf_url)
		}
	}
}
</script>

<style scoped>
/deep/ .ivu-modal-body {
  padding: 0;
}
</style>